<template>
  <!-- 新增新通证申购 -->
  <div>新增新通证申购</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
